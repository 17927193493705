code[class*="language-"], pre[class*="language-"] {
  color: #657b83;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  tab-size: 4;
  hyphens: none;
  font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
  font-size: 1em;
  line-height: 1.5;
}

pre[class*="language-"]::-moz-selection {
  background: #073642;
}

pre[class*="language-"] ::-moz-selection {
  background: #073642;
}

code[class*="language-"]::-moz-selection {
  background: #073642;
}

code[class*="language-"] ::-moz-selection {
  background: #073642;
}

pre[class*="language-"]::selection, pre[class*="language-"] ::selection, code[class*="language-"]::selection, code[class*="language-"] ::selection {
  background: #073642;
}

pre[class*="language-"] {
  border-radius: .3em;
  margin: .5em 0;
  padding: 1em;
  overflow: auto;
}

:not(pre) > code[class*="language-"], pre[class*="language-"] {
  background-color: #fdf6e3;
}

:not(pre) > code[class*="language-"] {
  border-radius: .3em;
  padding: .1em;
}

.token.comment, .token.prolog, .token.doctype, .token.cdata {
  color: #93a1a1;
}

.token.punctuation {
  color: #586e75;
}

.token.namespace {
  opacity: .7;
}

.token.property, .token.tag, .token.boolean, .token.number, .token.constant, .token.symbol, .token.deleted {
  color: #268bd2;
}

.token.selector, .token.attr-name, .token.string, .token.char, .token.builtin, .token.url, .token.inserted {
  color: #2aa198;
}

.token.entity {
  color: #657b83;
  background: #eee8d5;
}

.token.atrule, .token.attr-value, .token.keyword {
  color: #859900;
}

.token.function, .token.class-name {
  color: #b58900;
}

.token.regex, .token.important, .token.variable {
  color: #cb4b16;
}

.token.important, .token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.tw-pt-4 {
  padding-top: 1rem;
}

.tw-pl-8 {
  padding-left: 2rem;
}

.tw-bg-red-100 {
  background-color: #f7d8d6;
}

body {
  background: #fff;
  min-height: 100vh;
}

#root {
  height: 100%;
  font-family: Ubuntu, sans-serif !important;
  font-weight: 300 !important;
}

#main {
  height: 100vh;
}

#login-testing {
  margin-top: 20px;
}

#login-form {
  height: 100%;
}

#login-form .button {
  background-color: #008cff;
}

#login-form .grid.middle.aligned {
  height: 100%;
}

.route-component {
  --header-height: 52px;
  height: calc(100vh - var(--header-height)  - 40px);
  width: 90%;
  max-width: 1400px;
  margin: 20px auto;

  &.packages {
    height: calc(100vh - var(--header-height)  - 80px);
    max-width: 1500px;
  }
}

.legacy-page {
  height: calc(100vh - var(--header-height)  - 100px);
}

.paginated-table {
  height: calc(100vh - var(--header-height)  - 80px);
}

.form-button-sked-blue {
  color: #fff !important;
  background-color: #008cff !important;
}

.back-button {
  color: #008cff;
}

#modal-header-add-team, #modal-header-install-package {
  color: #fff;
  background-color: #008cff;
}

#modal-header-add-login-option {
  color: #fff;
  background-color: #fff;
}

#modal-content-add-team {
  background-color: #f4f6f9;
}

#modal-button-add-team {
  color: #fff;
  background-color: #008cff;
  margin-top: 25px;
}

#modal-header-add-customer {
  color: #fff;
  background-color: #008cff;
}

#modal-content-add-customer {
  background-color: #f4f6f9;
}

#modal-button-add-customer {
  color: #fff;
  background-color: #008cff;
  margin-top: 25px;
}

#modal-submit-no-padding {
  color: #fff;
  background-color: #008cff;
}

.ui.button.basic.modal-delete-menu, .ui.button.basic.modal-delete-menu:hover, .ui.button.basic.modal-delete-menu:focus {
  box-shadow: none !important;
  color: red !important;
  background: 0 !important;
  border: 0 !important;
  border-radius: 0 !important;
}

.ui.button.basic.modal-reset-menu, .ui.button.basic.modal-reset-menu:hover, .ui.button.basic.modal-reset-menu:focus {
  box-shadow: none !important;
  color: #000 !important;
  background: 0 !important;
  border: 0 !important;
  border-radius: 0 !important;
}

.ui.button.radio {
  text-align: left;
  padding: 14px;
}

.ui.button.radio.selected {
  border: 2px solid #008cff;
  padding: 12px;
}

.ui.button.datadog {
  padding: 2px !important;
}

i.icon.datadog {
  width: 26px;
  height: 26px !important;
}

.back-button .chevron.icon {
  color: #008cff;
}

#edit-customer-selector {
  border-color: #85b7d9;
}

.sked-blue-button .ui.button {
  color: #fff;
  background-color: #008cff;
}

.clickable {
  cursor: pointer;
}

.console {
  white-space: pre-wrap;
  background: #fff;
  flex-grow: 1;
  height: calc(100% - 150px);
  padding: 10px;
  font-family: "monospace";
  overflow-y: scroll;
  box-shadow: inset 0 0 1px #000;
}

.deployment-details {
  padding-left: 20px;
}

.deployment-details th {
  border-bottom: none;
}

.scrollable {
  max-height: calc(100% - var(--header-height));
  margin: 15px auto;
  overflow-y: auto;
}

.scrollable-with-pagination {
  max-height: calc(100% - var(--header-height)  - 80px);
  margin: 15px auto;
  overflow-y: auto;
}

.scrollable-no-margin {
  max-height: 100%;
  overflow-y: auto;
}

.application-deployments {
  height: calc(100vh - 230px);
  min-height: 480px;
}

.table-cell-softwrap {
  overflow-wrap: anywhere;
}

.table-cell-ellipsis {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-width: 400px;
  display: -webkit-box;
  overflow: hidden;
}

.table-cell-overflow {
  overflow-wrap: break-word;
  word-break: break-all;
}

.table-cell-no-wrap {
  white-space: nowrap;
}

.circle-icon-button {
  color: #fff !important;
  background-color: #008cff !important;
  padding: .3em !important;
}

.square-icon-button {
  padding: .5em !important;
}

.alert-toast {
  z-index: 1002;
  max-width: 35%;
  position: absolute;
  top: 10px;
  right: 10px;
}

.sticky-table th {
  z-index: 2;
  position: sticky;
  top: 0;
  background-color: #f9fafb !important;
}

.action-menu {
  margin: 0;
  font-weight: bold !important;
  box-shadow: 0 0 3px #6b7280 !important;
}

.overflow-hide {
  position: relative;
}

.overflow-hide:after {
  content: "";
  background: linear-gradient(90deg, #fff0 0%, #fff 70%);
  width: 50px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.create-team-note {
  color: #4a556a;
  padding-bottom: 6px;
  font-size: 13px;
}

.create-team-header {
  margin-bottom: 6px !important;
}

.show {
  transition: all .2s ease-in-out;
}

.show.sf {
  height: 83px;
}

.show.sf.error {
  height: 129px;
}

.show.user {
  height: 280px;
}

.show.login {
  height: 193px;
}

.hide {
  opacity: 0;
  height: 0;
  transition: all .2s ease-in-out;
  overflow: hidden;
  margin: 0 !important;
}

#radio-logo {
  width: 22px;
  margin: 0 8px 0 0;
}

#header {
  background-color: var(--bg);
  border: var(--border);
  margin: 0;
}

#header .column {
  color: var(--text);
  flex-direction: row;
  padding: 0;
  display: flex;
}

#header-col-1 {
  justify-content: flex-start;
  gap: 20px;
  padding: 14px 20px !important;
}

#header-col-2 {
  justify-content: center;
}

#header-col-3 {
  justify-content: flex-end;
}

#header-menu {
  background-color: initial;
  box-shadow: none;
  border: none;
  font-size: 14px;
}

#header-menu .item {
  color: inherit;
  background: none;
}

#header-menu .item:before {
  background: none;
}

#header-menu .item:hover, #header-menu .active.item {
  text-decoration: underline;
}

#header-settings {
  color: var(--text);
  background: none;
  padding: 0;
  box-shadow: none !important;
}

#header-logo {
  min-height: 18px !important;
  max-height: 24px !important;
}

#env-label {
  padding: 4px 8px;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  font-family: Ubuntu, sans-serif !important;
}

#user-avatar {
  background-color: #5e687a;
  align-content: center;
  width: 32px;
  height: 32px;
  margin: 0;
  font-size: 12px;
  line-height: 19px;
  font-family: Ubuntu, sans-serif !important;
}

#header-info-menu {
  padding: 10px 20px !important;
  text-decoration: none !important;
}

#header-info-menu .item {
  text-decoration: none !important;
}

#header-info-menu .divider {
  margin: 0;
}

#settings-divider {
  border-left: 1px solid #cacfd9;
  margin: 10px 0;
}

#old-model-warning {
  letter-spacing: -.13px;
  text-align: center;
  font-size: 13px;
}

@media only screen and (width <= 767px) {
  #header-col-menu {
    text-align: left;
  }
}

#landing-page {
  color: #223049;
  height: 100%;
}

#landing-page h4 {
  text-align: left;
}

#landing-page .row {
  padding: 0;
}

#landing-page .column {
  padding: 8px;
}

#landing-page .button {
  padding: 20px;
}

#landing-nav-button {
  color: var(--text);
  background-color: var(--bg);
  text-align: left;
  border: 1px solid #cacfd9;
  font-weight: 500;
}

#landing-nav-button span {
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  display: inline-flex;
}

#landing-nav-button .icon {
  text-align: right;
}

#landing-welcome {
  justify-content: space-between;
  height: 100%;
  display: flex;
}

.ui.small.statistics .statistic > .value.small {
  vertical-align: middle;
  margin: 0 0 0 5px;
  font-size: 1rem !important;
}

.ui.attached.tabular.menu {
  flex-wrap: wrap;
}

i.icon.lambda.circle:before {
  content: "𝝺";
  color: #fff;
}

i.icon.lambda.circle.orange {
  background-color: orange;
  border-radius: 50%;
  width: 1em;
  height: 1em;
}
/*# sourceMappingURL=index.1691fffe.css.map */
